import React from "react"
import Layout from "../components/layout/Layout"
import MyJumbo from "../components/myJumbo/MyJumbo"
import MyButton from "../components/myButton/MyButton"

import "../fontawesome/css/all.min.css"
import "../index.scss"

import { Container, Row, Col } from "react-bootstrap"

import CV from "../../static/ArnaudCortisseResume.pdf"
import Experiences from "../components/about/Experience"
import { Pages } from "../pages"
import {
  nbProfessionalCompanies,
  nbYearsOfProfessionalExperience,
} from "../components/about/Experiences"

export default () => {
  return (
    <Layout page={Pages.ABOUT}>
      <MyJumbo
        title={"tldr; About me."}
        content={
          <>
            <Row>
              <Col>
                <p>
                  I've been a professional developer for about{" "}
                  {nbYearsOfProfessionalExperience} years. I worked for about 8
                  years at {nbProfessionalCompanies} different companies and for
                  about 1 year on a personal project.
                </p>
                <p>
                  I started out with 'low-level programming' (C, C++, drivers,
                  etc.) and decided to transition to 'high-level programming'. I
                  learned web development by creating my own project (DoNotSkip)
                  and I then got hired based on that project.
                </p>
                <p>
                  The technologies I particularly enjoy working with these days
                  are: Typescript, React and Node.
                </p>
              </Col>
            </Row>
            <MyButton text={"Download CV"} URL={CV} />
          </>
        }
      />
      <hr />
      <Container fluid>
        <Row>
          <Col>
            <h3>Work Experience</h3>
          </Col>
        </Row>
        <Experiences />
        <div className="mt-5" />
        <hr />
        <Row>
          <Col>
            <h3>Education</h3>
          </Col>
        </Row>
        <Container fluid>
          <Row>
            <Col>
              <h5>Bachelor, Information Technology</h5>
            </Col>
          </Row>
          <Row>
            <Col>Institut Supérieur d'Enseignement Technologique</Col>
            <Col>2012 - 2015</Col>
          </Row>
          <Row>
            <Col>
              <p className="mt-3">
                <strong>Specialization:</strong>
                <br /> Advanced Language
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
