import React from "react"
import { Row, Col } from "react-bootstrap"

export interface ExperienceFieldProps {
  jobTitle: string
  company: string
  period: string
  description: JSX.Element
  companyWebsite: string
  professional: boolean
  companyId: string
  startingDate: Date
  endDate: Date | undefined
}

const ExperienceField: React.FC<ExperienceFieldProps> = ({
  jobTitle,
  company,
  period,
  description,
  companyWebsite,
}) => {
  return (
    <div className="mt-3">
      <Row>
        <Col>
          <h5>{jobTitle}</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <a target="_blank" href={companyWebsite}>
            {company}
          </a>
        </Col>
        <div className="bg-light p-1 mr-2">{period}</div>
      </Row>
      <Row>
        <Col>{description}</Col>
      </Row>
    </div>
  )
}

export default ExperienceField
