import React, { useState } from "react"
import ExperienceField, { ExperienceFieldProps } from "./ExperienceField"
import { Tabs, Tab } from "react-bootstrap"
import { experiences } from "./Experiences"

const Experiences = () => {
  const [key, setKey] = useState("0")

  return (
    <Tabs id="test" activeKey={key} onSelect={(k: string) => setKey(k)}>
      {experiences.map((experienceField, index) => {
        return (
          <Tab eventKey={index} key={index} title={experienceField.company}>
            <ExperienceField
              jobTitle={experienceField.jobTitle}
              company={experienceField.company}
              period={experienceField.period}
              description={experienceField.description}
              companyWebsite={experienceField.companyWebsite}
            />
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default Experiences
